///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

	.image {
		display: inline-block;
		position: relative;

		img {
			display: block;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 2rem 2rem 0;
			top: 0.25rem;
		}

		&.right {
			float: right;
			margin: 0 0 2rem 2rem;
			top: 0.25rem;
		}

		&.fit {
			display: block;
			margin: 0 0 _size(element-margin) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: 0 0 (_size(element-margin) * 1.5) 0;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}