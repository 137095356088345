///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 1rem 0 0;
			text-align: center;

			&:last-child {
				padding-right: 0;
			}

			.icon {
				@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out, border-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out');
				background-color: transparent;
				border-radius: 100%;
				box-shadow: inset 0 0 0 1px _palette(accent);
				color: _palette(accent);
				display: block;
				font-family: _font(family-heading);
				font-size: 1.25rem;
				font-weight: _font(weight-bold);
				height: 2.75rem;
				line-height: 2.75rem;
				margin: 0 auto _size(element-margin) auto;
				width: 2.75rem;

				&:hover {
					box-shadow: inset 0 0 0 1px lighten(_palette(accent), 10);
					color: lighten(_palette(accent), 10);

					&:active {
						background-color: _palette(accent);
						color: _palette(invert, fg-bold);
					}

				}
			}
		}

		@include breakpoint('<=small') {
			li {
				.icon {
					font-size: 1.25rem;
					height: 2.75rem;
					line-height: 2.75rem;
					width: 2.75rem;
				}
			}
		}
	}