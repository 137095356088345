///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Header */

	header {

		&.heading {
			border-bottom: 1px solid;
			margin: 0 auto  _size(element-margin) auto;
			max-width: 85%;
			text-align: center;
			width: _size(inner-width) / 1.75;
		}

		&.major {
			margin: 0 auto (_size(element-margin) * 2) auto;
			max-width: 80%;

			h1 {
				font-size: 3rem;
			}
		}

		h2 {
			margin-bottom: 0.50rem;
		}

		h3 {
			margin-bottom: 0;
		}

		h2 + p, h1 + p {
			font-size: 1.5rem;
		}

		@include breakpoint('<=medium') {
			&.major {
				margin: 0 auto _size(element-margin) auto;
			}
		}

		@include breakpoint('<=small') {
			h2 + p, h1 + p {
				font-size: 1.15rem;
			}

			&.major {
				max-width: 80%;
			}
		}
	}

	@mixin color-header($p: null) {
		header {
			&.heading {
				border-color: _palette($p, border);
			}

			p {
				color: _palette(accent);
			}
		}
	}

	@include color-header;