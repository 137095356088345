///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Menu */

	$accent: invert;

	#menu {
		@include color-typography($accent);
		@include color-button($accent);
		@include vendor('transform', 'translateX(#{_size(menu-width)})');
		@include vendor('transition', (
			'transform #{_duration(menu)} ease',
			'box-shadow #{_duration(menu)} ease',
			'visibility #{_duration(menu)}'
		));
		-webkit-overflow-scrolling: touch;
		background: lighten(_palette(accent), 5);
		box-shadow: none;
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		padding: 3rem 2rem;
		position: fixed;
		right: 0;
		top: 0;
		visibility: hidden;
		width: _size(menu-width);
		z-index: _misc(z-index-base) + 2;

		> ul {
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&.links {
				list-style: none;
				padding: 0;

				> li {
					padding: 0;

					> a {
						border: 0;
						border-top: solid 1px _palette($accent, border);
						color: _palette($accent, fg-bold);
						display: block;
						font-weight: _font(weight-bold);
						letter-spacing: _size(letter-spacing-alt);
						line-height: 3.5rem;
						text-decoration: none;

						&:hover {
							color: transparentize(_palette($accent, fg-bold), 0.25);
						}
					}

					&:first-child {
						> a {
							border-top: 0;
						}
					}
				}
			}
		}

		.close {
			@include icon(false, solid);
			@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			border: 0;
			color: _palette($accent, fg-bold);
			cursor: pointer;
			display: block;
			height: 3.25rem;
			line-height: 3.25rem;
			padding-right: 1.25rem;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			vertical-align: middle;
			width: 7rem;

			&:before {
				content: '\f00d';
				font-size: 1.25rem;
			}

			&:hover {
				color: transparentize(_palette($accent, fg-bold), 0.25);
			}

			@include breakpoint('<=small') {
				height: 4rem;
				line-height: 4rem;
			}
		}

		@include breakpoint('<=small') {
			padding: 2.5rem 1.75rem;
		}
	}

	body.is-menu-visible {
		#menu {
			@include vendor('transform', 'translateX(0)');
			box-shadow: 0 0 1.5rem 0 rgba(0,0,0,0.2);
			visibility: visible;
		}
	}