@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'libs/fixed-grid';
// @import 'libs/fontawesome-all.min.css';
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Source+Sans+Pro:300,400');

/*
	Venue by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

	@include breakpoints((
		default:   (1681px,   null     ),
		xlarge:    (1281px,   1680px   ),
		large:     (981px,    1280px   ),
		medium:    (737px,    980px    ),
		small:     (481px,    736px    ),
		xsmall:    (361px,    480px    ),
		xxsmall:   (null,     360px    )
	));

// Color.

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-header($p);
		@include color-list($p);
		@include color-table($p);
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';
	@import 'base/fontawsome';

// Component.

	@import 'components/header';
	@import 'components/row';
	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/table';
	@import 'components/features';
	@import 'components/spotlights';
	@import 'components/profiles';
	@import 'components/wrapper';
	@import 'components/inner';

// Layout.

	@import 'layout/header';
	@import 'layout/menu';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/footer';