///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		3.25rem,
		element-margin:		2rem,
		inner-width:	80rem,
		header-height:		3.25rem,
		menu-width:			20rem
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Arial, Helvetica, sans-serif),
		family-heading:		('Fjalla One', sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		400
	);

// Palette.
	$palette: (
		bg:					#ffffff,
		bg-alt:				#f3f3f3,
		fg:					#282828,
		fg-bold:			#282828,
		fg-light:			#bbbbbb,
		border:				rgba(0,0,0,0.15),
		border-bg:			rgba(0,0,0,0.075),
		border-bg-alt:		rgba(0,0,0,0.05),
		highlight:			invert,

		accent:				#094a7c,

		invert: (
			bg:					#353535,
			bg-alt:				#2f2f2f,
			fg:					rgba(255,255,255,0.50),
			fg-bold:			#ffffff,
			fg-light:			rgba(255,255,255,0.25),
			border:				rgba(255,255,255,0.15),
			border-bg:			rgba(255,255,255,0.075),
			border-bg-alt:		rgba(255,255,255,0.05),
		)

	);