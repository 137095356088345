///
/// Venue by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Profiles */

	.profiles {
		@include fixed-grid-base;
		width: 100%;
		margin-bottom: _size(element-margin);

		.profile {
			text-align: center;

			> :last-child {
				margin-bottom: 0;
			}

			ul {
				&.icons {
					li {
						.icon {
							margin-bottom: 0;
						}
					}
				}
			}

			.image {
				border-radius: 100%;
				border: 1px solid _palette(accent);
				padding: 0.75rem;
				margin-bottom: _size(element-margin);
				max-width: 168px;
				img {
					border-radius: 100%;
					max-width: 100%;
				}
			}
		}

		@include breakpoint('>large') {
			@include fixed-grid-gutters(2, 4rem);
		}

		@include breakpoint('large') {
			@include fixed-grid-gutters(2, 2rem);
			margin-bottom: (_size(element-margin) * 2) 0;
		}

		@include breakpoint('medium') {
			@include fixed-grid-gutters(1, 5rem);
		}

		@include breakpoint('<=medium') {
			.profile {
				margin: 0 auto;
				max-width: 35rem;

				&:last-child {
					padding-bottom: 0;
				}
			}
		}

		@include breakpoint('<=small') {
			@include fixed-grid-gutters(1, 3rem);
		}
	}